import { thunk } from 'easy-peasy';
import api from '~/services/api';
import { getRefreshToken, getClientId, deleteTokens } from '~/services/auth';
import { IThunkLogout, IAuthModel } from '../types';
import { IStoreModel } from '../../types';

const onLogout: IThunkLogout = thunk<IAuthModel, void, any, IStoreModel>(
  async ({ reset, setAuthorized }, _, { getStoreActions }) => {
    try {
      const refreshToken = getRefreshToken();
      const clientId = getClientId();

      await api.auth.logOut({
        refreshToken,
        clientId,
      });

      getStoreActions().snackbar.hide();
    } catch {
      deleteTokens();
    } finally {
      reset();
      setAuthorized();
    }
  },
);

export default onLogout;
