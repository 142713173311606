import { ReactElement, useCallback, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { endOfMonth, formatISO, startOfMonth } from 'date-fns';
import styles from './MonthRangeDatePicker.module.scss';

interface IProps {
  initialStartDate: string;
  initialEndDate: string;
  setStartDate: (value: string) => void;
  setEndDate: (value: string) => void;
}

const MonthRangeDatePickerComponent = ({
  initialStartDate,
  initialEndDate,
  setStartDate,
  setEndDate,
}: IProps): ReactElement => {
  const [dateRange, setDateRange] = useState([
    new Date(initialStartDate),
    new Date(initialEndDate),
  ]);
  const [startDate, endDate] = dateRange;

  const onChange = useCallback(
    (newDateRange: [Date, Date]) => {
      const [newStartDate, newEndDate] = newDateRange;

      const adjustedStartDate = newStartDate === null ? null : startOfMonth(newStartDate);
      const adjustedEndDate = newEndDate === null ? null : endOfMonth(newEndDate);

      if (adjustedStartDate !== null && adjustedEndDate !== null) {
        setStartDate(formatISO(adjustedStartDate, { representation: 'date' }));
        setEndDate(formatISO(adjustedEndDate, { representation: 'date' }));
      }

      setDateRange([adjustedStartDate, adjustedEndDate]);
    },
    [setDateRange, setStartDate, setEndDate],
  );

  return (
    <DatePicker
      className={styles.datePicker}
      dateFormat="MMM yyyy"
      startDate={startDate}
      endDate={endDate}
      selectsRange
      showMonthYearPicker
      disabledKeyboardNavigation
      onChange={onChange}
    />
  );
};

export default MonthRangeDatePickerComponent;
