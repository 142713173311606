import { ReactElement, useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import Grid from '@material-ui/core/Grid';
import classNames from 'classnames';
import Input from '~/ui/components/inputs/Input';
import AutoSubmit from '~/ui/components/inputs/AutoSubmit';
import { IStatusPatientsFilters } from '~/services/api/patients/types';
import DatePicker from '~/ui/components/inputs/DatePicker';
import SelectVirtualized from '~/ui/components/inputs/SelectVirtualized';
import { IIdName } from '~/services/api/types';
import api from '~/services/api';
import { extractErrorMessage } from '~/utils/error';
import { useStoreActions } from '~/store/hooks';
import { PatientsPopupType } from '../types';
import styles from './Header.module.scss';
import { getInitialValues } from './form';

const colors = {
  [PatientsPopupType.Active]: 'blue',
  [PatientsPopupType.Approved]: 'green',
  [PatientsPopupType.Admitted]: 'purple',
  [PatientsPopupType.Discharged]: 'red',
};

interface IProps {
  type: PatientsPopupType;
  total: number;
  onSubmit: (filters: IStatusPatientsFilters) => void;
}

const Header = ({ type, total, onSubmit }: IProps): ReactElement => {
  const [programs, setPrograms] = useState<IIdName[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  const showError = useStoreActions(actions => actions.snackbar.showError);

  const initialValues = getInitialValues(type);

  const formMethods = useForm<IStatusPatientsFilters>({
    defaultValues: initialValues,
  });

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = formMethods;

  const programOptions = useMemo(() => {
    const options = programs.map(item => ({
      value: item.id,
      label: item.name,
    }));

    return [
      {
        value: '',
        label: 'All Programs',
      },
      ...options,
    ];
  }, [programs.length]);

  const handleOnSubmit = (values: IStatusPatientsFilters) => {
    onSubmit(values);
  };

  const onMount = async () => {
    setIsLoading(true);

    try {
      const selectors = await api.patients.getSelectors().then(res => res.data);
      setPrograms(selectors.programs);
    } catch (e) {
      showError(extractErrorMessage(e));
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    onMount();
  }, []);

  return (
    <FormProvider {...formMethods}>
      <form className={styles.container} onSubmit={handleSubmit(handleOnSubmit)}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs>
            <Input name="fullName" label="Name" register={register} errors={errors} />
          </Grid>
          <Grid item xs>
            <Input name="subjectId" label="Patient ID" register={register} errors={errors} />
          </Grid>
          <Grid item xs>
            <DatePicker
              placeholder=".. /.. /.."
              label="Start Date"
              name="startDate"
              maxDate={new Date()}
              control={control}
              errors={errors}
            />
          </Grid>
          <Grid item xs>
            <DatePicker
              placeholder=".. /.. /.."
              label="End Date"
              name="endDate"
              maxDate={new Date()}
              control={control}
              errors={errors}
            />
          </Grid>
          <Grid item xs>
            <SelectVirtualized
              name="programId"
              control={control}
              errors={errors}
              options={programOptions}
              isLoading={isLoading}
              label="Program Name"
            />
          </Grid>
          <Grid item>
            <Grid
              container
              className={classNames(styles.total, styles[colors[type]])}
              alignItems="center"
              justifyContent="center"
            >
              <Grid item>{total}</Grid>
            </Grid>
          </Grid>
          <AutoSubmit debounce={1000} initialValues={initialValues} onSubmit={handleOnSubmit} />
        </Grid>
      </form>
    </FormProvider>
  );
};

export default Header;
