import axios from 'axios';
import { IResponse } from '~/services/api/types';
import {
  IPatient,
  IPatientsSelectors,
  IPatientSelectors,
  IPatientCarePlanSelectors,
  IPatientOverview,
  IPatientOverviewFilters,
  IPatientDetails,
  IPatientList,
  IPatientsListFilters,
  IPatientInfo,
  IStatusPatientsFilters,
  IStatusPatient,
  IUpdateQuickNotesRequest,
  IUpdateIsSmokerRequest,
  IPatientOverviewAverage,
  IPatientOverviewAverageFilters,
} from './types';

const apiUrl = 'patients';

export const getList = (params: IPatientsListFilters): IResponse<IPatientList[]> =>
  axios({
    method: 'get',
    url: apiUrl,
    params,
  });

export const getByStatus = (params: IStatusPatientsFilters): IResponse<IStatusPatient[]> =>
  axios({
    method: 'get',
    url: `${apiUrl}/by-status`,
    params,
  });

export const get = (id: number): IResponse<IPatient> =>
  axios({
    method: 'get',
    url: `${apiUrl}/${id}`,
  });

export const getOverview = (
  patientId: number,
  params: IPatientOverviewFilters,
): IResponse<IPatientOverview> =>
  axios({
    method: 'get',
    url: `${apiUrl}/${patientId}/overview`,
    params,
  });

export const getOverviewAverage = (
  patientId: number,
  params: IPatientOverviewAverageFilters,
): IResponse<IPatientOverviewAverage> =>
  axios({
    method: 'get',
    url: `${apiUrl}/${patientId}/overview-average`,
    params,
  });

export const getInfo = (patientId: number): IResponse<IPatientInfo> =>
  axios({
    method: 'get',
    url: `${apiUrl}/${patientId}/info`,
  });

export const getDetails = (patientId: number): IResponse<IPatientDetails> =>
  axios({
    method: 'get',
    url: `${apiUrl}/${patientId}/details`,
  });

export const getSelectors = (): IResponse<IPatientsSelectors> =>
  axios({
    method: 'get',
    url: `${apiUrl}/selectors`,
  });

export const getSelectorsById = (id: number, programId?: number): IResponse<IPatientSelectors> =>
  axios({
    method: 'get',
    url: `${apiUrl}/${id}/selectors`,
    params: {
      programId,
    },
  });

export const getCarePlanSelectorsById = (id: number): IResponse<IPatientCarePlanSelectors> =>
  axios({
    method: 'get',
    url: `${apiUrl}/${id}/care-plan-selectors`,
  });

export const getCarePlanReport = (id: number): IResponse<Blob> =>
  axios({
    method: 'get',
    url: `${apiUrl}/${id}/care-plan`,
    responseType: 'blob',
  });

export const create = (
  programId: number,
  data: IPatient,
  deviceIds: number[],
): IResponse<IPatient> =>
  axios({
    method: 'post',
    url: apiUrl,
    data,
    params: {
      programId,
      deviceIds,
    },
  });

export const update = (data: IPatient): IResponse<IPatient> =>
  axios({
    method: 'put',
    url: apiUrl,
    data,
  });

export const remove = (id: number): IResponse<void> =>
  axios({
    method: 'delete',
    url: `${apiUrl}/${id}`,
  });

export const discharge = (id: number): IResponse<void> =>
  axios({
    method: 'post',
    url: `${apiUrl}/${id}/discharge`,
    data: [],
  });

export const activate = (id: number): IResponse<void> =>
  axios({
    method: 'post',
    url: `${apiUrl}/${id}/activate`,
    data: [],
  });

export const updateQuickNotes = (
  id: number,
  data: IUpdateQuickNotesRequest,
): IResponse<IPatientInfo> =>
  axios({
    method: 'put',
    url: `${apiUrl}/${id}/quick-notes`,
    data,
  });

export const updateIsSmoker = (id: number, data: IUpdateIsSmokerRequest): IResponse<IPatientInfo> =>
  axios({
    method: 'put',
    url: `${apiUrl}/${id}/is-smoker`,
    data,
  });
